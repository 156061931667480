import TimestampFormats from "@/enum/TimestampFormats"

function getDate(timestamp: string) {
    const originalDate = timestamp.split('T')[0]
    const dateArray = originalDate.split('-')

    return `${dateArray[2]}/${dateArray[1]}/${dateArray[0].slice(-2)}`
}

function getTime(timestamp: string) {
    const time = timestamp.split('T')[1]
    const hhmmss = time.split('.')[0]

    return hhmmss
}

function getTimeWithMillis(timestamp: string) {
    const time = timestamp.split('T')[1]
    const hhmmssmmm = time.split('+')[0]

    return hhmmssmmm
}

function getDateAndTime(timestamp: string) {
    return `${getDate(timestamp)} ${getTime(timestamp)}`
}

function getDateAndTimeWithMillis(timestamp: string) {
    return `${getDate(timestamp)} ${getTimeWithMillis(timestamp)}`
}

export function formatTimestamp(timestamp: string, format: TimestampFormats): string {
    switch(format) {
        case TimestampFormats.DATE:
            return getDate(timestamp)
        case TimestampFormats.DATE_TIME:
            return getDateAndTime(timestamp)
        case TimestampFormats.DATE_TIME_MILLIS:
            return getDateAndTimeWithMillis(timestamp)
        case TimestampFormats.TIME:
            return getTime(timestamp)
        case TimestampFormats.TIME_MILLIS:
            return getTimeWithMillis(timestamp)
        default:
            return getDateAndTime(timestamp)
    }
}

export function getTimestampLabel(timestampFormat: TimestampFormats): string {
    switch(timestampFormat) {
        case TimestampFormats.DATE:
            return 'Data'
        case TimestampFormats.DATE_TIME:
            return 'Data e Hora'
        case TimestampFormats.DATE_TIME_MILLIS:
            return 'Data e Hora com Milisegundos'
        case TimestampFormats.TIME:
            return 'Hora'
        case TimestampFormats.TIME_MILLIS:
            return 'Hora com Milisegundos'
        default:
            return timestampFormat
    }
}