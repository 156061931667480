import { store } from "@/store"
import { LOGOUT } from "@/store/action-types"
import axios, { AxiosInstance } from "axios"

const httpClient: AxiosInstance = axios.create({
    baseURL: "VUE_APP_BACKEND_ADDRESS" in process.env ? 
                `${process.env.VUE_APP_BACKEND_ADDRESS}/api`
                : 'http://localhost:8080/api',
    headers: {
        'Content-Type': 'application/json'
    }
})

httpClient.interceptors.request.use(config => {
    const token = localStorage.getItem('token')
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    return config
})

httpClient.interceptors.response.use(undefined, error => {
    if (error.response.status === 401) {
        store.dispatch(LOGOUT)
    }
    return Promise.reject(error)
})

export default httpClient
