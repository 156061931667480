export const RESET_ALL = "RESET_ALL"

export const GET_DASHBOARDS = "GET_DASHBOARDS"

export const CREATE_DASHBOARD = "CREATE_DASHBOARD"
export const UPDATE_DASHBOARD = "UPDATE_DASHBOARD"
export const DELETE_DASHBOARD = "DELETE_DASHBOARD"

export const CREATE_CHART = "CREATE_CHART"
export const UPDATE_CHART = "UPDATE_CHART"
export const DELETE_CHART = "DELETE_CHART"

export const FETCH_DATA = "FETCH_DATA"
export const FETCH_DATA_HISTORY = "FETCH_DATA_HISTORY"
export const FETCH_BATCH_DATA = "FETCH_BATCH_DATA"
export const FETCH_CONTROLLERS = "FETCH_CONTROLLERS"
export const DOWNLOAD_CSV = "DOWNLOAD_CSV"

export const START_POLLING = "START_POLLING"
export const STOP_POLLING = "STOP_POLLING"
export const START_HISTORY_POLLING = "START_HISTORY_POLLING"
export const STOP_HISTORY_POLLING = "STOP_HISTORY_POLLING"

export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"

//USERS
export const GET_COMPANIES = "GET_COMPANIES"
export const GET_ADMINS = "GET_ADMINS"

export const CREATE_COMPANY = "CREATE_COMPANY"
export const UPDATE_COMPANY = "UPDATE_COMPANY"
export const DELETE_COMPANY = "DELETE_COMPANY"

export const CREATE_USER = "CREATE_USER"
export const UPDATE_USER = "UPDATE_USER"
export const DELETE_USER = "DELETE_USER"

export const CREATE_ADMIN = "CREATE_ADMIN"

export const UPDATE_PASSWORD = "UPDATE_PASSWORD"

//ALARMS
export const GET_RULES = "GET_RULES"

export const CREATE_RULE = "CREATE_RULE"
export const UPDATE_RULE = "UPDATE_RULE"
export const DELETE_RULE = "DELETE_RULE"
