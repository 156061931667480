import { InjectionKey } from "vue"
import { Store, createStore, useStore as vuexUseStore } from "vuex"
import { DashboardState, dashboard } from "./modules/dashboard"
import { MetricsState, metrics } from "./modules/metrics"
import { AuthState, auth } from "./modules/auth"
import IChart from "@/interfaces/charts/IChart"
import IChartData from "@/interfaces/charts/IChartData"
import IChartDataHist from "@/interfaces/charts/IChartDataHist"
import { RESET_ALL } from "./action-types"
import { RESET } from "./mutation-types"
import { UsersState, users } from "./modules/users"
import { alarms, AlarmState } from "./modules/alarms"

export interface State {
    dashboard: DashboardState,
    metrics: MetricsState,
    auth: AuthState,
    users: UsersState,
    alarms: AlarmState
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
    modules: {
        dashboard,
        metrics,
        auth,
        users,
        alarms
    },
    getters: {
        getChartData: (state, getters) => (chart: IChart<any>): IChartData[] => {
            const chartData = [] as IChartData[]

            chart.valueFields.forEach(vf => {
                const data = getters.getLastValue(vf)

                if (data) chartData.push(data)
            })

            return chartData
        },
        getChartDataHist: (state) => (chart: IChart<any>): IChartDataHist[] => {
            let data

            if (chart.id) {
                data = state.metrics.dataHist.get(chart.id)
            }

            if (!data) {
                return createEmptyHitoricChartDataHistory(chart)
            }
            
            return data
        }
    },
    actions: {
        [RESET_ALL]({commit}) {
            commit(RESET)
        }
    }
})

export function useStore(): Store<State> {
    return vuexUseStore(key)
}

function createEmptyHitoricChartDataHistory(chart: IChart<any>): IChartDataHist[] {
    const emptyHist = [] as IChartDataHist[]

    chart.valueFields.forEach(vf => {
        emptyHist.push({
            name: vf.name,
            values: [{
                timestamp: '1970-01-01T00:00:00.000',
                value: 0
            }],
            pageNumber: 0,
            totalPages: 0,
            totalElements: 0,
            pageSize: 0
        })
    })

    return emptyHist
}